@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Herr+Von+Muellerhoff');
* {
    background: transparent;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    vertical-align: baseline;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
    color: #191919;
    font: 500 16px/20px "Poppins", sans-serif;
    letter-spacing: 1.6;
    line-height: 26px !important;
    overflow-x: hidden; // background: #F2F2F2;
}

a {
    text-decoration: none !important;
    cursor: pointer;
}

h1,
.h1 {
    font: 400 35px 'Playfair Display', serif;
}

.btn-primary {
    color: #232323;
    font-size: 12px;
    font-weight: 600;
    border: 2px solid #232323;
    padding: 9px 27px;
    background: transparent;
    border-radius: 0px;
    letter-spacing: 1px;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        background: #8e7037;
        border: 2px solid #8e7037;
        color: #FFF;
    }
}

/*menu style*/

.wrape-header {
    width: 100%;
    height: 106px;
    background: #fff;
}

.wr-logo {
    text-align: center;
    background: #dcdcdc;
    padding: 10px;
    height: 106px;
    >a {
        color: #BA843B;
    }
}

.wr-menu {
    width: 100%;
    height: 70px;
    padding-top: 20px;
}

.nav_menu ul {
    padding: 0;
    margin: 0;
}

.nav_menu ul li {
    list-style: none;
    padding: 10px 0px;
    display: inline-block;
}

.nav_menu ul li a {
    font-size: 14px;
    padding: 8px 15px;
    transition: 0.1s;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #3D1F15;
}

.nav_menu ul li a:hover,
.nav_menu ul li a:focus,
.nav_menu ul li a.menuactive {
    color: #BA843B;
}

.navbar-toggle .icon-bar {
    background: #191919;
}

.navbar-toggle {
    margin-top: 16px;
    border: none;
    margin-right: 10px;
}

.wr-btn-bookNow {
    height: 106px;
    text-align: center;
    background: #742314;
}

.wr-btn-bookNow div {
    display: block;
    padding: 32px;
    transition: 0.2s;
    text-transform: uppercase;
    color: #eee;
}

.wr-btn-bookNow a:hover,
.wr-btn-bookNow a:focus {
    background: #191919;
}

.nav_menu .dropdown-menu li {
    padding: 0px !important;
    margin: 0px !important;
    width: 100% !important;
    >a {
        padding: 10px 20px !important;
    }
}

.caret-up {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

@media(min-width: 992px) and (max-width: 1045px) {
    .nav_menu ul li a {
        font-size: 12px;
    }
}

@media (max-width: @screen-xs-max) {
    .wr-logo {
        height: 106px;
        background: none;
    }
    .wr-logo img {
        padding-top: 10px;
    }
    .wrape-header {
        height: 190px;
    }
    .wr-menu {
        width: 100%;
        height: auto;
        padding-top: 0;
        text-align: center;
    }
    .nav_menu .nav li {
        display: block;
        padding: 0;
        text-align: left;
        border-top: 1px solid #cccccc05;
    }
    .nav_menu ul li a {
        padding: 10px 15px 10px 30px;
        display: block;
        color: #aaa;
    }
    .navbar-toggle {
        width: 100%;
        margin-right: 0;
        margin-top: 0px;
    }
    .navbar-toggle .icon-bar {
        margin: 0 auto;
    }
    .navbar-collapse {
        position: absolute;
        top: 59px;
        width: 100%;
        padding: 0;
        position: absolute;
        width: 100%;
        z-index: 9999;
        background: #191919;
    }
    .hidden-sm .navbar-collapse {
        margin-top: -16px;
    }
    .nav_menu ul.dropdown-menu {
        position: unset;
        margin-left: 0px !important;
        width: 100%;
        background: none;
        padding-left: 0;
        box-shadow: none;
        border-bottom: none;
    }
    .nav_menu .dropdown-menu li>a {
        padding: 10px 20px 10px 45px !important;
    }
    .nav_menu .dropdown-menu>li>a:hover,
    .nav_menu .dropdown-menu>li>a:focus {
        background: none !important;
    }
    .wr-btn-bookNow {
        width: 197px;
        height: 35px;
        margin: 0 auto;
        margin-top: 5px;
    }
    .wr-btn-bookNow div {
        display: block;
        padding: 7px;
    }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .wr-menu {
        padding-top: 10px;
    }
    .sm-menu {
        position: absolute;
        top: 80px;
        width: 100%;
    }
    .nav_menu ul li {
        display: block;
        padding: 0;
        border-top: 1px solid #cccccc05;
    }
    .nav_menu ul li a {
        padding: 10px 15px 10px 30px;
        display: block;
        color: #aaa;
    }
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
        margin-top: 23px;
    }
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-collapse {
        position: absolute;
        width: 100%;
        z-index: 9999;
        padding: 0;
        background: #191919;
        margin-top: 26px;
    }
    .navbar-nav {
        float: none;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-collapse.collapse.in {
        display: block !important;
    }
    .wr-collapse {
        display: none;
    }
    .nav_menu ul.dropdown-menu {
        position: unset;
        margin-left: 0px !important;
        width: 100%;
        background: none;
        padding-left: 0;
        box-shadow: none;
        border-bottom: none;
    }
    .nav_menu .dropdown-menu li>a {
        padding: 10px 20px 10px 45px !important;
    }
    .nav_menu .dropdown-menu>li>a:hover,
    .nav_menu .dropdown-menu>li>a:focus {
        background: none !important;
    }
    .nav_menu .dropdown.parent.open ul.dropdown-menu {
        display: block;
    }
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }
    .fa.fa-volume-control-phone {
        display: block;
        padding-bottom: 5px;
    }
    .wr-btn-bookNow div {
        padding: 26px;
    }
}

/*End menu style*/

/*Style Slide Show*/

.wrp-arrow-slide {
    position: absolute;
    right: 5%;
    bottom: 10%;
    >a {
        padding: 5px;
        >i {
            color: white;
            font-size: 24px;
            background: rgba(1, 1, 1, 0.3);
            padding: 10px;
            text-align: center;
            transition: .2s;
        }
        >i:hover {
            background: rgba(1, 1, 1, 0.5);
        }
    }
}

.slide-show {
    position: relative;
}

.in-slide-tile {
    background: rgba(1, 1, 1, .5);
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
    font-family: 'Playfair Display', serif;
    position: absolute;
    bottom: 30%;
    left: 5%;
}

.in-slide-des {
    background: rgba(1, 1, 1, .5);
    padding: 10px;
    color: #fff;
    position: absolute;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    max-width: 35%;
    min-width: 24%;
    line-height: 30px;
    top: 73%;
    left: 5%;
}

@media (max-width: @screen-xs-max) {
    #maximage,
    .mc-image {
        height: 350px !important;
    }
    .wrp-arrow-slide {
        bottom: 50%;
    }
    .in-slide-tile {
        font-size: 20px;
    }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    #maximage,
    .mc-image {
        height: 450px !important;
    }
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    #maximage,
    .mc-image {
        height: 550px !important;
    }
}

@media only screen and (min-width: 1200px) {
    #maximage,
    .mc-image {
        height: 600px !important;
    }
}

@media (max-width: 480px) {
    .wrp-arrow-slide {
        bottom: 62%;
    }
}

.wrap-slideshow {
    position: relative;
}

/*End Slide Show*/

/* Start booking form*/

.wrap-book-form .check-avail {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    padding: 1% 0px;
    z-index: 9;
    background-color: #ffffff29;
}

.wrap-book-form .check-avail.check-avail-v3 {
    position: initial;
    padding: 50px 0;
}

.wrap-book-form .check-avail.check-avail-v3 .date-title {
    border: 1px solid #ebebeb;
}

.wrap-book-form .check-avail .date-title {
    height: 130px;
    margin-right: 15px;
}

.wrap-book-form .check-avail .date-title:last-child {
    margin-right: 0px;
}

.wrap-book-form .check-avail .date-title label {
    color: #8E7037;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.wrap-book-form .check-avail .container {
    display: flex;
    flex-direction: row;
}

.wrap-book-form .check-avail .container .arrival,
.wrap-book-form .check-avail .container .departure {
    width: 27.2%;
    background: #fff;
    padding: 24px 26px 30px 35px;
    cursor: pointer;
    overflow: hidden;
}

.wrap-book-form .check-avail .container .arrival input,
.wrap-book-form .check-avail .container .departure input {
    height: 55px;
    border: none;
    box-shadow: none;
    padding-left: 0;
    color: #b3b3b3;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    padding-right: 0;
    margin-bottom: 10px;
}

@media (max-width: 1354px) {
    .wrap-book-form .check-avail .container .arrival input,
    .wrap-book-form .check-avail .container .departure input {
        font-size: 20px;
    }
    .wrap-book-form .check-avail .container .children .btn-group .btn,
    .wrap-book-form .check-avail .container .adults .btn-group .btn {
        font-size: 48px !important;
        padding: 10px !important;
        width: 110px !important;
    }
}

@media (max-width: 1200px) {
    .wrap-book-form .check-avail .container .arrival input,
    .wrap-book-form .check-avail .container .departure input {
        height: 47px;
        font-size: 20px;
    }
}

.wrap-book-form .check-avail .container .arrival .input-group-addon,
.wrap-book-form .check-avail .container .departure .input-group-addon {
    background: #fff;
    border: none;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 20px;
}

@media (max-width: 1200px) {
    .wrap-book-form .check-avail .container .arrival .input-group-addon,
    .wrap-book-form .check-avail .container .departure .input-group-addon {
        padding-bottom: 10px;
    }
    .wrap-book-form .check-avail .container .arrival .input-group-addon img,
    .wrap-book-form .check-avail .container .departure .input-group-addon img {
        width: 45px;
    }
}

@media (max-width: 992px) {
    .wrap-book-form .check-avail .container .arrival .input-group-addon,
    .wrap-book-form .check-avail .container .departure .input-group-addon {
        padding: 0px 10px 18px 0px;
    }
    .wrap-book-form .check-avail .container .arrival .input-group-addon img,
    .wrap-book-form .check-avail .container .departure .input-group-addon img {
        width: 35px;
    }
    .wrap-book-form .check-avail .container .arrival input,
    .wrap-book-form .check-avail .container .departure input {
        font-size: 21px;
        height: 36px;
    }
    .wrap-book-form .check-avail .container .children .btn-group .btn,
    .wrap-book-form .check-avail .container .adults .btn-group .btn {
        font-size: 35px !important;
        width: 90px !important;
    }
}

.wrap-book-form .check-avail .container .children,
.wrap-book-form .check-avail .container .adults {
    background: #fff;
    width: 12%;
    text-align: center;
    padding-top: 24px;
}

.wrap-book-form .check-avail .container .children .btn-group,
.wrap-book-form .check-avail .container .adults .btn-group {
    margin-top: -17px;
}

.wrap-book-form .check-avail .container .children .btn-group .btn,
.wrap-book-form .check-avail .container .adults .btn-group .btn {
    font-size: 56px;
    padding: 6px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #353535;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    width: 132px;
}

.wrap-book-form .check-avail .container .children .btn-group .dropdown-menu,
.wrap-book-form .check-avail .container .adults .btn-group .dropdown-menu {
    border: none;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    min-width: 141px;
    top: 110%;
    left: 0px;
}

.wrap-book-form .check-avail .container .children .btn-group .dropdown-menu .active>a,
.wrap-book-form .check-avail .container .adults .btn-group .dropdown-menu .active>a {
    background: #8E7037;
}

.wrap-book-form .check-avail .container .children .btn-group .dropdown-menu a:hover,
.wrap-book-form .check-avail .container .adults .btn-group .dropdown-menu a:hover {
    background: #8E7037;
    color: white;
}

.wrap-book-form .check-avail .container .find_btn {
    color: white;
    background: #8e7037;
    width: 16.3%;
}

.wrap-book-form .check-avail .container .find_btn:hover {
    background: #fff;
    border: 2px solid #8E7037;
    cursor: pointer;
}

.wrap-book-form .check-avail .container .find_btn:hover .text-find {
    color: #8E7037;
}

.wrap-book-form .check-avail .container .find_btn .text-find {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding-top: 45px;
    color: white;
    cursor: pointer;
}

.wrap-book-form .check-avail .container .find_btn .text-find:hover {
    color: #8E7037;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .wrap-book-form .check-avail .date-title {
        height: 100px;
    }
    .wrap-book-form .check-avail .arrival,
    .wrap-book-form .check-avail .departure {
        padding: 15px 20px 10px 20px !important;
    }
    .wrap-book-form .check-avail .adults,
    .wrap-book-form .check-avail .children {
        padding-top: 15px !important;
    }
    .wrap-book-form .check-avail .adults .btn-group,
    .wrap-book-form .check-avail .children .btn-group {
        margin-top: -28px !important;
    }
    .wrap-book-form .check-avail .adults .btn-group .dropdown-menu,
    .wrap-book-form .check-avail .children .btn-group .dropdown-menu {
        min-width: 111px !important;
        top: 94% !important;
        left: 0px;
    }
    .wrap-book-form .check-avail .adults .btn-group .btn,
    .wrap-book-form .check-avail .children .btn-group .btn {
        width: 111px !important;
        margin-top: 8px;
    }
    .wrap-book-form .check-avail .find_btn .text-find {
        padding-top: 28px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .wrap-book-form .check-avail .date-title {
        height: 85px;
    }
    .wrap-book-form .check-avail .date-title label {
        font-size: 13px;
    }
    .wrap-book-form .check-avail .arrival,
    .wrap-book-form .check-avail .departure {
        padding: 10px 5px 27px 15px !important;
    }
    .wrap-book-form .check-avail .adults,
    .wrap-book-form .check-avail .children {
        padding-top: 10px !important;
    }
    .wrap-book-form .check-avail .adults .btn-group,
    .wrap-book-form .check-avail .children .btn-group {
        margin-top: -28px !important;
    }
    .wrap-book-form .check-avail .adults .btn-group .dropdown-menu,
    .wrap-book-form .check-avail .children .btn-group .dropdown-menu {
        min-width: 82px !important;
        top: 100% !important;
        left: 0 !important;
    }
    .wrap-book-form .check-avail .adults .btn-group .btn,
    .wrap-book-form .check-avail .children .btn-group .btn {
        width: 82px !important;
        font-size: 50px !important;
        margin-top: 10px;
    }
    .wrap-book-form .check-avail .find_btn .text-find {
        padding-top: 23px !important;
        font-size: 12px !important;
        letter-spacing: 2px !important;
    }
}

@media (max-width: 767px) {
    .wrap-book-form.height-v .item img {
        width: 290%;
        max-width: 300%;
    }
    .wrap-book-form .check-avail {
        position: inherit;
        background: rgba(2, 2, 2, 0.78);
    }
    .wrap-book-form .check-avail.check-avail-v3 {
        padding: 0;
    }
    .wrap-book-form .check-avail .container {
        flex-direction: row;
        display: inline-block;
    }
    .wrap-book-form .check-avail .container .date-title {
        height: 85px;
        width: 49%;
        margin-top: 10px;
        float: left;
        margin-right: 0px;
    }
    .wrap-book-form .check-avail .container .date-title:nth-child(2),
    .wrap-book-form .check-avail .container .date-title:nth-child(4) {
        float: right;
    }
    .wrap-book-form .check-avail .container .date-title:nth-child(5) {
        width: 100%;
        margin-bottom: 5px;
        border: none;
    }
    .wrap-book-form .check-avail .container .date-title label {
        font-size: 13px;
    }
    .wrap-book-form .check-avail .container .date-title img {
        width: 40px;
    }
    .wrap-book-form .check-avail .container .arrival,
    .wrap-book-form .check-avail .container .departure {
        padding: 10px 5px 27px 15px;
    }
    .wrap-book-form .check-avail .container .arrival input,
    .wrap-book-form .check-avail .container .departure input {
        font-size: 24px;
    }
    .wrap-book-form .check-avail .container .adults,
    .wrap-book-form .check-avail .container .children {
        padding-top: 10px;
    }
    .wrap-book-form .check-avail .container .adults .btn-group,
    .wrap-book-form .check-avail .container .children .btn-group {
        margin-top: -28px;
    }
    .wrap-book-form .check-avail .container .adults .btn-group .dropdown-menu,
    .wrap-book-form .check-avail .container .children .btn-group .dropdown-menu {
        min-width: 80px;
        top: 100%;
        left: 0;
    }
    .wrap-book-form .check-avail .container .adults .btn-group .btn,
    .wrap-book-form .check-avail .container .children .btn-group .btn {
        width: 80px;
        font-size: 50px;
        margin-top: 10px;
    }
    .wrap-book-form .check-avail .container .find_btn .text-find {
        padding-top: 23px;
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .wrap-book-form .check-avail {
        position: inherit;
        background: rgba(2, 2, 2, 0.78);
    }
    .wrap-book-form .check-avail .container {
        flex-direction: column;
    }
    .wrap-book-form .check-avail .container .date-title {
        height: 85px;
        width: 100%;
        margin-top: 10px;
    }
    .wrap-book-form .check-avail .container .date-title label {
        font-size: 14px;
    }
    .wrap-book-form .check-avail .container .date-title img {
        width: 40px;
    }
    .wrap-book-form .check-avail .container .arrival,
    .wrap-book-form .check-avail .container .departure {
        padding: 10px 5px 27px 15px;
    }
    .wrap-book-form .check-avail .container .adults,
    .wrap-book-form .check-avail .container .children {
        padding-top: 10px;
    }
    .wrap-book-form .check-avail .container .adults .btn-group,
    .wrap-book-form .check-avail .container .children .btn-group {
        margin-top: -28px;
    }
    .wrap-book-form .check-avail .container .adults .btn-group .dropdown-menu,
    .wrap-book-form .check-avail .container .children .btn-group .dropdown-menu {
        min-width: 80px;
        top: 101%;
        left: 0;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.47);
    }
    .wrap-book-form .check-avail .container .adults .btn-group .btn,
    .wrap-book-form .check-avail .container .children .btn-group .btn {
        width: 80px;
        font-size: 50px;
        margin-top: 10px;
    }
    .wrap-book-form .check-avail .container .find_btn .text-find {
        padding-top: 23px;
        font-size: 12px;
    }
}

/* Start DATEPICKER */

input.readonly {
    background: transparent !important;
    cursor: pointer !important;
}

.datepicker.dropdown-menu {
    font-family: 'Poppins', sans-serif !important;
    z-index: 99 !important;
    min-width: 239px !important;
    border: none !important;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
    td {
        border: 1px solid transparent !important;
        -webkit-border-radius: 0;
        border-radius: 0;
        &.day {
            padding: 5px 10px;
        }
    }
}

.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
    border: 1px solid #8E7037 !important;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background: transparent;
}

.datepicker table tr td.active.active {
    background: #8E7037 !important;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    border: 1px solid transparent !important;
}

.datepicker {
    padding: 0 !important;
}

.table-condensed {
    border-collapse: initial;
    border-spacing: 0px;
    .today {
        background-color: #8f7037 !important;
        background-image: none !important;
        color: white !important;
        &:hover {
            background-color: #8f7037 !important;
            color: white !important;
        }
    }
    thead {
        tr {
            .prev,
            .datepicker-switch,
            .next {
                background: #8f7037 !important;
                -webkit-border-radius: 0;
                border-radius: 0;
                color: #FFF;
                padding: 10px 0px;
            }
            .prev,
            .next {
                font-size: 0;
            }
            .datepicker-switch {
                text-transform: uppercase;
                font-family: "Poppins", sans-serif;
                font-weight: 600;
                vertical-align: middle;
            }
            .prev::after {
                content: "\f060";
                display: inline-block;
                font-family: 'Font Awesome\ 5 Free';
                font-weight: 900;
                font-size: 16px;
                vertical-align: middle;
            }
            .next::after {
                content: "\f061";
                display: inline-block;
                font-family: 'Font Awesome\ 5 Free';
                font-weight: 900;
                font-size: 16px;
                vertical-align: middle;
            }
            .dow {
                background: #977A42;
                -webkit-border-radius: 0;
                border-radius: 0;
                color: #FFF;
                font-family: "Poppins", sans-serif;
                font-weight: 600;
                padding: 6px 0px;
                text-transform: uppercase;
                font-size: 12px;
            }
        }
    }
}

/* End Date Picker*/

/* Start Home Style*/

.section-1 {
    background: #f2f2f2;
}

.outline-under {
    width: 100%;
    height: 25px;
    background: url(../uploads/images/icon-our.png) no-repeat center center;
    margin-top: 10px;
}

.home-descr {
    margin: 0 auto;
    margin-top: 15px;
    color: #333;
    font-size: 16px;
    font-weight: 300;
}

/* Start Offers*/

.avail-offers article {
    color: #333;
    font-size: 16px;
    font-weight: 300;
    background: #fff;
    margin-top: 30px;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
    transition: .2s;
    min-height: 380px;
    &:hover {
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
    }
}

.avail-offers article .card-block {
    padding: 10px 20px;
    padding-bottom: 24px;
}

.avail-offers article .card-block ul {
    list-style: inside;
}

.avail-offers article .card-block .card-title {
    color: #BA843B;
}

.avail-offers article .card-img {
    overflow: hidden;
    position: relative;
    >a>img {
        transition: all .3s ease-out;
    }
}

.avail-offers article:hover img {
    transform: scale(1.2, 1.2);
}

/* End Offers*/

/* Start Hotel Facilites*/

.hotel-facilites {
    background: url(../uploads/images/home/hotel.jpg) no-repeat center center / cover #000000 fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
    .facilities-container {
        height: 100%;
        background: #00000080;
    }
}

.hotel-facilites .container {
    margin-bottom: 50px
}

.hotel-facilites .title h2 {
    color: #fff;
}

.hotel-facilites .nav-tabs {
    border-bottom: none;
    text-align: center;
}

.nav-tabs>li {
    float: none;
    display: inline-block;
}

.hotel-facilites .nav-tabs>li>a {
    color: black;
    font-size: 18px;
    background: white;
    border-radius: 0px;
    border-bottom: none;
    transition: .2s ease-out;
    &:hover {
        border-top: 3px solid #BA843B;
    }
    &:focus {
        color: #BA843B;
    }
}

.hotel-facilites .nav-tabs>li.active>a,
.hotel-facilites .nav-tabs>li.active>a:hover,
.hotel-facilites .nav-tabs>li.active>a:focus {
    color: #BA843B;
    background-color: #ffffff;
    border-top: 3px solid;
    border-bottom: none;
    cursor: default;
    border-radius: 0px;
}

.hotel-facilites .tab-content {
    background: #fff;
    padding: 50px 20px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    >.tab-pane h3 {
        margin-top: 0px !important;
    }
    ol {
        list-style-position: inside;
    }
}

@media (max-width: @screen-xs-max) {
    .nav-tabs>li {
        float: none;
        display: block;
        text-align: left;
        margin-bottom: 3px;
    }
    .hotel-facilites .nav-tabs>li.active>a,
    .hotel-facilites .nav-tabs>li.active>a:hover,
    .hotel-facilites .nav-tabs>li.active>a:focus {
        border-left: 3px solid;
        border-top: 0px;
        border-bottom: 0px;
        cursor: default;
        border-radius: 0px;
    }
    .hotel-facilites .nav-tabs>li>a:hover {
        border-top: 0px;
        border-left: 3px solid #BA843B;
    }
    .hotel-facilites .tab-content .tab-pane h3 {
        margin-top: 10px !important;
    }
}

/* End Hotel Facilites*/

/* Start Footer*/

.footer-sky.footer-sky-v4 {
    padding-top: 45px;
    background: url("../images/Home-4/footer.jpg") no-repeat;
    width: 100%;
    background-size: cover;
    background-position: center center;
}

.footer-sky.footer-sky-v4 .footer-top {
    background: 0;
}

.footer-sky.footer-sky-v4 .footer-top h2 {
    font-weight: 700;
    color: #FFF;
    font-size: 40px;
    letter-spacing: 2px;
}

.footer-sky.footer-sky-v4 .footer-top p {
    font-size: 16px;
    font-weight: 300;
    color: #FFF;
}

.footer-sky.footer-sky-v4 .icon-email {
    display: inline-block;
}

.footer-sky.footer-sky-v4 .footer-bottom .footer-icon a {
    padding: 0px 17px;
    color: #FFF;
    font-size: 18px;
}

.footer-sky.footer-sky-v4 .footer-bottom .footer-icon a:hover {
    color: #8E7037;
}

.footer-sky.footer-sky-v4 .footer-bottom .footer-bottom-l {
    color: #ebebeb;
    font-size: 13px;
    font-weight: 300;
}

.footer-sky.footer-sky-v4 .footer-bottom .footer-bottom-l a {
    font-weight: 400;
    color: #8E7037;
}

.footer-sky.footer-sky-v4 .footer-bottom .footer-bottom-l a:hover {
    color: #8E7037;
}

.footer-sky.footer-sky-v4 .textbox {
    margin-bottom: 50px;
    margin-top: 45px;
}

.footer-sky.footer-sky-v4 .textbox .input-group {
    width: 570px !important;
    border: 2px solid white;
}

.footer-sky.footer-sky-v4 .textbox .input-group .form-control {
    background: transparent !important;
}

.footer-sky.footer-sky-v4 .textbox .input-group .btn {
    background: transparent !important;
}

.footer-sky.footer-sky-v4 .textbox .input-group .btn {
    color: #FFF !important;
    border-left: 1px solid #FFF !important;
}

.footer-sky .footer-top {
    padding: 32px 0;
    margin: -2px 0;
    background: #2e2e2e;
}

.footer-sky .footer-top.footer-top-v3 {
    background: 0;
    padding-bottom: 100px;
}

.footer-sky .footer-top.footer-top-v3 .bg-v3 {
    background: url("../images/Home-3/news.jpg") no-repeat;
    padding: 43px 60px;
    background-size: cover;
    background-position: center center;
}

.footer-sky .footer-top.footer-top-v3 .bg-v3 .form-control {
    background: rgba(212, 206, 206, 0.31) !important;
}

.footer-sky .footer-top.footer-top-v3 .bg-v3 .form-control::placeholder {
    color: #FFF;
}

.footer-sky .footer-top.footer-top-v3 .bg-v3 .btn {
    background: transparent !important;
}

.footer-sky .footer-top.footer-top-v3 .bg-v3 .btn {
    border-left: 1px solid #747d86 !important;
    color: #FFF !important;
}

.footer-sky .footer-top .textbox .form-inline {
    margin-top: 2px;
}

.footer-sky .footer-top .textbox .form-inline .form-group .input-group {
    width: 450px;
}

.footer-sky .footer-top .textbox .form-inline .form-group .input-group .form-control {
    font-size: 14px;
    font-weight: 300;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    background: #3E3E3E;
    border: none;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    padding: 6px 25px;
    height: 60px;
}

.footer-sky .footer-top .textbox .form-inline .form-group .input-group .form-control::placeholder {
    color: #FFF;
}

.footer-sky .footer-top .textbox .form-inline .form-group .input-group .form-control:focus {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.footer-sky .footer-top .textbox .form-inline .form-group .input-group .btn {
    width: 80px;
    height: 60px;
    border: none;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background: #3E3E3E;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: absolute;
    right: 10px;
    z-index: 99;
}

.footer-sky .footer-top .textbox .form-inline .form-group .input-group .btn:hover {
    color: #ffffff;
}

.footer-sky .footer-top .textbox .form-inline .form-group .input-group .btn {
    color: #B2B2B2;
    font-size: 30px;
    line-height: 0px;
    border-left: 1px solid #4c4c4c;
    padding-left: 25px;
}

.footer-sky .footer-top .footer-icon-l .fab {
    line-height: 64px;
    color: #ffffff;
    font-size: 20px;
    padding-left: 43px;
    text-align: center !important;
}

.footer-sky .footer-top .footer-icon-l .fab:hover {
    color: #8e7037;
}

.footer-sky .footer-bottom {
    border-top: 1px solid #2e2e2e;
    font-size: 13px;
    font-weight: 300;
    color: #ebebeb;
    line-height: 100px;
}

.footer-sky .footer-bottom.bottom-v3 {
    line-height: 85px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    color: #ebebeb;
    font-weight: 300;
    font-size: 13px;
}

.footer-sky .footer-bottom.bottom-v3 a {
    color: #8E7037;
}

.footer-sky .footer-bottom .no-padding {
    padding: 0;
}

.footer-sky .footer-bottom a {
    font-weight: 500;
    color: #8E7037;
}

.footer-sky .footer-bottom a:hover {
    color: #8E7037;
}

.footer-sky .footer-bottom .payments ul {
    padding: 0;
    margin: 0;
}

.footer-sky .footer-bottom .payments ul li {
    list-style: none;
    display: inline-block;
    padding-left: 25px;
}

.footer-sky .footer-mid {
    background: #232323;
}

.footer-sky .footer-mid .list-info {
    color: #b2b2b2;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    .fas {
        color: #8E7037;
    }
}

.footer-sky .footer-mid.footer-v3 {
    background: url("../images/Home-3/footer.jpg") no-repeat;
    width: 100%;
    background-size: cover;
    background-position: center center;
}

.footer-sky .footer-mid.footer-v3 .padding-footer-mid {
    padding: 45px 0px 67px 0px;
}

.footer-sky .footer-mid .padding-footer-mid .list-content-top {
    margin-top: 55px;
}

.footer-sky .footer-mid .padding-footer-mid .list-content-child {
    margin-top: 97px;
}

@media (max-width: 991px) {
    .footer-sky .footer-mid.footer-v3 .padding-footer-mid {
        padding: 20px 0px 30px 0px;
    }
    .footer-sky .footer-mid .list-info {
        text-align: center;
    }
}

.footer-sky .footer-mid .padding-footer-mid {
    padding: 85px 0px 30px 0px;
}

.footer-sky .footer-mid .padding-footer-mid .list-content.content-v3 ul li {
    margin-bottom: 12px;
}

.footer-sky .footer-mid .padding-footer-mid .list-content.content-v3 ul li a {
    color: #FFF;
}

.footer-sky .footer-mid .padding-footer-mid .list-content ul {
    padding: 0;
}

.footer-sky .footer-mid .padding-footer-mid .list-content ul li {
    list-style: none;
    margin-bottom: 16px;
}

.footer-sky .footer-mid .padding-footer-mid .list-content ul li a {
    color: #b2b2b2;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.footer-sky .footer-mid .padding-footer-mid .list-content ul li a:hover {
    color: #8e7037;
    text-decoration: none;
}

@media (min-width: 993px) and (max-width: 1500px) {
    .footer-icon {
        width: 100%;
    }
    .social-reviews {
        margin-right: 10%;
    }
    .social-reviews .col-lg-2 {
        width: 20%;
    }
    .social-reviews .col-lg-1 {
        width: 20%;
    }
    .footer-sky .footer-top .textbox .form-inline .form-group .input-group {
        width: 300px;
    }
}

@media (max-width: @screen-sm-max) {
    .social-reviews {
        padding-top: 10%;
    }
    .social-reviews .col-lg-2 {
        width: 26%;
    }
    .social-reviews .col-lg-1 {
        width: 20%;
    }
}

@media (max-width: @screen-xs-max) {
    .social-reviews .col-lg-2 {
        margin-left: 25%;
        margin-right: 8%;
        width: 20%;
    }
    .social-reviews .col-lg-1 {
        width: 20%;
    }
}

@media (max-width: 690px) {
    .social-reviews .col-lg-2 {
        margin-left: 24%;
        margin-bottom: 5px;
        width: 100%;
    }
    .social-reviews .col-lg-1 {
        width: 100%;
        margin-left: 36%;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .footer-sky.footer-sky-v4 .footer-bottom .payments li {
        padding-left: 19px;
    }
    .footer-sky .footer-top {
        padding: 20px;
    }
    .footer-sky .footer-top.footer-top-v3 {
        padding-bottom: 90px;
    }
    .footer-sky .footer-top.footer-top-v3 .bg-v3 .icon-email {
        margin: 0;
    }
    .footer-sky .footer-top.footer-top-v3 .bg-v3 .textbox {
        margin-left: -40px;
    }
    .footer-sky .footer-top.footer-top-v3 .bg-v3 .footer-icon-l .fab {
        padding-left: 40px;
    }
    .footer-sky .footer-top .icon-email {
        margin-top: 8px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .footer-sky.footer-sky-v4 .footer-bottom {
        line-height: 35px !important;
    }
    .footer-sky .footer-top {
        padding: 25px 0px;
    }
    .footer-sky .footer-top.footer-top-v3 {
        padding-bottom: 50px;
    }
    .footer-sky .footer-top.footer-top-v3 .bg-v3 {
        padding: 25px 60px;
    }
    .footer-sky .footer-top .textbox {
        text-align: center;
    }
    .footer-sky .footer-top .textbox .form-inline .form-group .input-group {
        width: 450px;
    }
    .footer-sky .footer-top .icon-email img {
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .footer-sky .footer-top .footer-icon-l {
        text-align: center;
        margin-top: 30px;
    }
    .footer-sky .footer-top .footer-icon-l .fab {
        line-height: 0px;
        padding: 0px 18px;
    }
    .footer-sky .footer-bottom {
        padding-top: 10px;
    }
    .footer-sky .footer-bottom .no-padding {
        line-height: 27px;
    }
    .footer-sky .footer-bottom .payments {
        text-align: center;
        padding-bottom: 13px;
    }
    .footer-sky .footer-bottom .payments ul li {
        padding: 0px 9px;
    }
    .footer-sky .footer-mid .padding-footer-mid {
        padding: 15px 0px 25px 0px;
    }
    .footer-sky .footer-mid .padding-footer-mid .list-content {
        text-align: center;
        margin-top: 35px;
        margin-bottom: 10px;
    }
    .footer-sky .footer-mid .padding-footer-mid .list-content img {
        max-width: 170px;
        vertical-align: middle;
    }
    .footer-sky .footer-mid .padding-footer-mid .list-content ul li {
        text-align: left;
    }
}

@media (max-width: 767px) {
    .footer-sky.footer-sky-v4 .footer-top {
        padding: 0;
    }
    .footer-sky.footer-sky-v4 .footer-bottom {
        line-height: 35px !important;
    }
    .footer-sky.footer-sky-v4 .textbox .input-group {
        width: 100% !important;
    }
    .footer-sky .footer-top {
        padding: 25px 0px;
    }
    .footer-sky .footer-top.footer-top-v3 {
        padding-bottom: 15px;
    }
    .footer-sky .footer-top.footer-top-v3 .footer-icon-l .fab {
        padding: 0 10px;
    }
    .footer-sky .footer-top.footer-top-v3 .bg-v3 {
        padding: 20px 15px;
    }
    .footer-sky .footer-top.footer-top-v3 .input-group {
        width: 420px !important;
    }
    .footer-sky .footer-top .textbox .form-inline .form-group .input-group {
        width: 450px;
        margin: 0 auto;
    }
    .footer-sky .footer-top .icon-email img {
        margin: 0 auto;
        margin-bottom: 10px;
    }
    .footer-sky .footer-top .footer-icon-l {
        text-align: center;
        margin-top: 10px;
    }
    .footer-sky .footer-top .footer-icon-l .fab {
        line-height: 0px;
        padding: 0px 18px;
    }
    .footer-sky .footer-bottom {
        text-align: center;
        padding-top: 10px;
    }
    .footer-sky .footer-bottom .no-padding {
        line-height: 30px;
    }
    .footer-sky .footer-bottom .payments {
        text-align: center;
        padding-bottom: 13px;
    }
    .footer-sky .footer-bottom .payments ul li {
        padding: 0px 9px;
    }
    .footer-sky .footer-mid .padding-footer-mid {
        padding: 15px 0px 20px 0px;
    }
    .footer-sky .footer-mid .padding-footer-mid .list-content {
        text-align: center;
        margin-top: 35px;
        margin-bottom: 10px;
    }
    .footer-sky .footer-mid .padding-footer-mid .list-content img {
        max-width: 170px;
        vertical-align: middle;
    }
    .footer-sky .footer-mid .padding-footer-mid .list-content ul li {
        text-align: left;
    }
}

@media (max-width: 480px) {
    .footer-sky.footer-sky-v4 .footer-top {
        padding: 0;
    }
    .footer-sky.footer-sky-v4 .footer-top h2 {
        font-size: 30px;
    }
    .footer-sky.footer-sky-v4 .footer-top p {
        font-size: 12px;
    }
    .footer-sky .footer-top.footer-top-v3 .input-group {
        width: 100% !important;
    }
    .footer-sky .footer-top .textbox .form-inline .form-group .input-group {
        width: 100%;
    }
    .footer-sky .footer-mid .padding-footer-mid {
        padding: 15px 0px 40px 0px;
    }
    .footer-sky .footer-mid .padding-footer-mid .col-xs-4 {
        width: 100%;
    }
    .footer-sky .footer-mid .padding-footer-mid .list-content ul li {
        text-align: center;
    }
    .footer-sky .footer-mid.footer-v3 .padding-footer-mid {
        padding: 30px 0px 40px 0px;
    }
    .footer-bottom {
        line-height: 45px !important;
    }
    .footer-bottom .pull-left,
    .footer-bottom .pull-right {
        float: none !important;
        text-align: center;
    }
}

/* End Footer*/

/* Start Header Page*/

.wr-cover-img {
    width: 100%;
    height: 270px;
    position: relative;
}

.wr-cover-bg {
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-position: 50% 40% !important;
    background-attachment: scroll !important;
    z-index: -1;
}

.wr-cover-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.wr-cover-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000009c;
}

.wr-cover-title h2 {
    color: #fff;
    font-size: 90px;
    text-align: center;
    text-transform: capitalize;
    font-family: 'Herr Von Muellerhoff', cursive;
}

.wr-cover-title .btn-secondary {
    text-align: center;
    padding: 30px 0;
    display: block;
}

.wr-cover-title .btn-secondary a {
    color: #fff;
    transition: 0.2s;
    padding: 10px 50px;
    background: none;
    border: 3px solid #fff;
    &:hover {
        background: #8e7037;
        border: 3px solid #8e7037; // color:#8e7037;
    } // text-transform: uppercase;
}

@media (max-width: @screen-xs-max) {
    .wr-cover-title h2 {
        font-size: 60px;
    }
}

/* End Header Page*/

/* Start Accomondation */

.wr-accomdation .card {
    min-height: 370px !important;
    .btn-primary {
        margin-bottom: 7px;
        float: none !important;
    }
    .card-block {
        padding-bottom: 0px;
    }
}

.wrp-acc-detail {
    h2.acc-title {
        font-size: 22px;
        font-style: italic;
        color: #BA843B;
        font-family: 'Playfair Display', sans-serif;
    }
    .wrp-detail-content {
        h2 {
            font-family: 'Playfair Display', sans-serif;
            font-size: 25px;
            color: #333;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 14px;
        }
        ul {
            list-style: none;
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
        }
        li {
            margin: 0;
            margin-bottom: .5em;
            padding-left: 1.5em;
            position: relative;
            &:after {
                content: '';
                height: .4em;
                width: .4em;
                background: #BA843B;
                display: block;
                position: absolute;
                transform: rotate(45deg);
                top: .6em;
                left: 0;
            }
        }
    }
}

@media (max-width: @screen-xs-max) {
    .wrp-acc-detail .wrp-detail-content ul {
        columns: 1 !important;
        -webkit-columns: 1 !important;
        -moz-columns: 1 !important;
    }
}

/* End Accomondation */

/* Start Hotel Facilites*/

.wr-facilites ul li {
    display: inline;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    padding: 5px 13px;
    margin-right: 5px;
    margin-top: 10px;
    border: 2px solid #dadada;
    color: #232323;
    transition: .2S;
    &:hover {
        color: #f2f2f2;
        background: #8E7037;
        cursor: pointer;
        border: 2px solid #8E7037;
    }
}

.wr-facilites .img {
    margin-bottom: 10px;
}

/* End Hotel Facilites*/

/* Start Offer Detail*/

.wrp-offer-detail {
    .offer-title,
    .offer-price {
        font-size: 22px;
        font-style: italic;
        color: #BA843B;
        font-family: 'Playfair Display', sans-serif;
        span {
            color: #742314;
            font-weight: 600;
        }
    }
    .offer-img img {
        width: 100%;
    }
    .wrp-detail-content {
        h2 {
            font-family: 'Playfair Display', sans-serif;
            font-size: 25px;
            color: #333;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 14px;
            margin-top: 45px;
        }
        ul {
            list-style: none;
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
        }
        li {
            margin: 0;
            margin-bottom: .5em;
            padding-left: 1.5em;
            position: relative;
            &:after {
                content: '';
                height: .4em;
                width: .4em;
                background: #BA843B;
                display: block;
                position: absolute;
                transform: rotate(45deg);
                top: .6em;
                left: 0;
            }
        }
    }
}

@media (max-width: @screen-xs-max) {
    .wrp-offer-detail .wrp-detail-content ul {
        columns: 1 !important;
        -webkit-columns: 1 !important;
        -moz-columns: 1 !important;
    }
    .offer-title,
    .offer-price {
        font-size: 16px !important;
    }
}

/* End Offer Detail*/

/* Start form book detail*/

.product-detail_book {
    background-color: #f1f1f1;
    margin-top: 52px;
}

.product-detail_book .product-detail_total {
    padding: 10px 30px;
    text-align: center;
    h3 {
        font-family: 'Playfair Display', sans-serif;
    }
}

.product-detail_book .product-detail_form {
    border-top: 1px solid #e4e4e4;
    padding: 10px 30px 27px 30px;
}

.product-detail_book .product-detail_form .btn-product {
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    padding: 10px;
    height: 45px;
    text-transform: uppercase;
    line-height: 20px;
    margin-top: 30px;
    background: #8E7037;
    border-radius: 0px;
    color: #ffff;
    transition: .2s;
    &:hover {
        background: #fff;
        border: 2px solid #8E7037;
        color: #8E7037;
    }
}

.check_availability-field {
    label {
        color: #232323;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        margin-top: 8px;
    }
    input {
        background: #fff !important;
        border-radius: 0px;
        padding: 8px;
    }
    .dropdown-toggle {
        width: 291px;
        background: #fff;
        text-align: left;
        color: #828282;
        border-radius: 0px;
        &:active {
            box-shadow: none;
        }
    }
    .dropdown-menu {
        width: 291px;
        border-radius: 0px;
        li.active a {
            background: #8E7037;
        }
    }
}

@media (max-width: @screen-xs-max) {
    .check_availability-field {
        .dropdown-toggle,
        .dropdown-menu {
            width: 221px;
        }
    }
}

.widget-other {
    background-color: #f1f1f1;
    padding: 20px 30px;
    margin-top: 50px;
    .widget-content {
        margin-bottom: 5px;
        background: #fff;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
        transition: .2s;
        &:hover {
            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
        }
        div {
            color: #8E7037;
        }
    }
    h3 {
        margin-top: 0px;
        font-family: 'Playfair Display', sans-serif;
    }
}

/* End form book detail*/

/* Start Main Gallery*/

.main-slideshow .slide-img {
    overflow: hidden;
    height: 234px;
    margin: 0px;
    padding: 1px;
    img {
        height: 100%;
        width: 100%;
    }
}

@media (max-width: 650px) {
    .main-slideshow .slide-img {
        width: 100%;
        height: auto;
        img {
            height: auto;
        }
    }
}

/*End Main Gallery*/

/*Start Things to do*/

.line-clamp {
    overflow: hidden;
}

.line-clamp p {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    margin: 0px;
}

/*Start Contact Form*/

.wrp-contact-form {
    .col-md-8 {
        padding-left: 0px;
    }
    .col-md-4 {
        border: 1px solid #cacaca;
        padding: 49px;
        padding-top: 20px;
        height: 278px;
    }
    input,
    textarea {
        border-radius: 0px;
    }
    textarea {
        height: 180px;
    }
    .form-control:focus {
        border-color: #8e7037;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px #bdb4a7;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px #bdb4a7;
    }
    h3 {
        font-size: 20px;
        margin-top: 0;
        text-transform: uppercase;
    }
    .fas {
        color: #8e7037;
        font-size: 18px;
    }
    .form-group label {
        color: #e44d32;
        font-weight: unset;
    }
}

@media(max-width: @screen-sm-max) {
    .wrp-contact-form .col-md-4 {
        margin-top: 30px;
        height: auto;
    }
    .wrp-contact-form .col-md-8 {
        padding: 0px;
    }
}